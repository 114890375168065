import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import HeaderIntroBlock from '../../components/Blocks/HeaderIntroBlock'
// import SectionBoxReady from '../../../components/outcomes/SectionBoxReady'
import SectionBoxCardText from '../../components/Platfrom/SectionBoxCardText'
import { FaqAccordionBlock } from '../../components/Blocks/AccordionBlock'
// import { navigate } from '@reach/router'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const HIPPA = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const [showSchedule, setShowSchedule] = useState(false)

     const handleShowSchedule = () => {
          setShowSchedule(true)
          if (window.location.href.includes('&')) {
               let newUrl = window.location.href + '&view-demo=1'
               window.history.replaceState(null, null, newUrl)
          } else {
               window.history.replaceState(null, null, '?view-demo=1')
          }
     }

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.search.substring('view-demo') === '?view-demo=1') {
               setShowSchedule(true)
          }
     }, [])

     const headerIntroContent = {
          // subtitle: 'subtitle demo',
          title: 'HIPAA Compliant Conversion Tracking & Marketing Analytics',
          // description: 'Replace or augment your use of Google Analytics with an alternative that is more powerful and privacy compliant.',
          substring: 'Covered entities can maintain the data they need to measure and improve marketing while remaining HIPAA compliant. ',
     }
     const headerIntroCard = {
          description: `<span>ListenLayer is uniquely positioned to help healthcare organizations remain compliant, because we give you back the control over your data... and we'll enter into a BAA with your organization.</span>`,
          fName: 'Zachary W. Randall',
          fPosition: 'Founder & CEO',
          imageURL: '/images/hero-image.png',
     }

     const dataCartText = [
          {
               title: 'Security-first Architecture',
               desc:
                    'We take the security of your data seriously, with full encryption, access controls, and proactive measures. This is what allows us to become HIPAA compliant. ListenLayer is currently pursuing SOC2, Type 2 compliance to reflect our commitment to security.',
          },
          {
               title: 'Compliant, Out of the Box',
               desc:
                    'ListenLayer allows marketers to remain compliant with privacy laws across the globe, even outside of HIPAA and Healthcare.  This foundation is what enables ListenLayer to be a strong partner to covered entities. ',
          },
          {
               title: 'Cookieless',
               desc:
                    'ListenLayer can operate 100% free of cookies, while still providing the benefits without the risks. This simplifies our compliance as you begin to centralize your data and privacy strategy. ',
          },
          {
               title: 'Server-Side Tagging',
               desc:
                    'ListenLayer enables you to use Server-side tagging with many destinations. This is what allows Healthcare organizations to pass data to marketing and analytics platforms while sanitizing PHI. ',
               learnMore: 'Learn more >',
               url: '/platform/server-side-tagging/',
          },
     ]

     const headline = 'Frequently Asked Questions'

     const faqs = [
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         "<p>Yes, but it's optional. ListenLayer has a full CMP (consent management platform) built in. Whenever your ready, turn off your old tool and enable ListenLayer's CMPs, or reach out to our support for help.</p>\n",
                    faqQuestion: 'Can ListenLayer replace my current cookie popup tool?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         '<p>Yes. ListenLayer integrates with a number of CMPs (consent management platforms), so you can continue to use your existing tool.</p>\n',
                    faqQuestion: 'Can I continue to use my current cookie/consent popup tool?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         "<p>Yes, we believe our platform is fully compliant. By default, and even if you do not enable ListenLayer's consent management platform, ListenLayer leverages regional consent rules to govern how users and people are identified and what data is collected. This means you can use ListenLayer worldwide, and remain in compliance with local regulations. Of course, these laws are always changing and we encourage you to consult your own counsel regarding your implementation.</p>\n",
                    faqQuestion: 'Is ListenLayer GDPR and CCPA compliant?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         '<p>Consent regulations govern how you identify users on your website, whether you can place cookies on their devices, and where you send the data. Marketers tend to have a very fragmented data strategy, with conversion tracking and data collection independently configured across an average of 6 platforms.</p>\n' +
                         "<p className='pt-4'>ListenLayer centralizes how you define conversions and data collection. It makes sense to define how you identify and use data, based on regional consent laws, in the single source of collection and truth. You can still distribute data to other platforms (Google Ads, Facebook Ads, Hubspot, Google Analytics), but within the context of your accurate, centralized consent settings.</p>\n",
                    faqQuestion: "What is the benefit of enabling ListenLayer's Consent Management Platform?",
               },
               type: 'WpCptFaq',
          },
     ]
     const buttonText = ''

     const pageName = 'HIPAA Compliance'
     const breadcrumbItems = [
          {
               name: 'Platform',
               url: `${'platform'}`,
          },
          {
               name: `${pageName}`,
          },
     ]

     return (
          <Layout>
               <SEO canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <HeaderIntroBlock headerIntroContent={headerIntroContent} headerIntroCard={headerIntroCard} />
               <section>
                    <div class="container">
                         <div class="headline-bodytext-wrapper bodytext-width-large">
                              <h3 class="text-center">Simplifying Complex Healthcare Analytics</h3>
                              <div class="bodyCopy">
                                   <p>
                                        ListenLayer allows you to build a centralized marketing data strategy, that you control, and that's governed
                                        in our platform under a BAA. You'll gain access to a full suite of analytics and customer data tools, with
                                        prebuilt reporting.
                                   </p>
                                   <p>
                                        And the best part: you can centralize and control how data is shared with destinations like Facebook Ads,
                                        Google Ads, GA4, and more. This allows you to architect a data sharing strategy that is HIPAA compliant while
                                        still providing your marketing team with the information they need to succeed.{' '}
                                   </p>
                              </div>
                         </div>
                    </div>
               </section>

               <section>
                    <div className="section-ready mt-5">
                         <div className="container">
                              <div className=" section-ready-box">
                                   <div className="title">
                                        <h3>Schedule a Demo for Healthcare Marketers</h3>
                                        <p>
                                             We'll show you how ListenLayer enables your team to drive better marketing outcomes while remaining HIPAA
                                             compliant.
                                        </p>
                                   </div>
                                   <div className="button-wrapper">
                                        <button
                                             className="button"
                                             to={'#'}
                                             style={{ padding: '21px 28px', fontSize: '15px' }}
                                             onClick={() => handleShowSchedule()}
                                        >
                                             Schedule Demo
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>

               <SectionBoxCardText data={dataCartText} />

               <section className="enhance-faq">
                    <div className="container">
                         <FaqAccordionBlock faqs={faqs} buttonText={buttonText} headline={headline} />
                    </div>
               </section>
          </Layout>
     )
}

export default HIPPA
